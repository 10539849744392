import React from 'react';

import Logo from '../images/Logo.png'
import Doctor from '../images/Doctor Icons.png'
import Patient from '../images/Patient Icons.png'

const Dashboard = () => {
    return (<>

<body class="antialiased font-ssans flex bg-blue-200 h-screen">
  <div class="mx-auto m-8 space-y-6 flex flex-col">
    {/* <!--  Monthly card start  --> */}

    <div className='mx-auto md:w-1/4 w-1/2 mb-8 '>
        <img src={Logo}  />
            
      </div>
    <div className='flex md:flex-row flex-col md:space-x-6 md:space-y-0 space-y-3 mx-auto my-auto rounded-3xl border-white px-10 bg-blue-100 md:p-32 w-full p-20'>
    <a href='https://patient.basic.docsun.health/'>
    <div class="pl-1 md:w-96 w-full h-20 bg-green-400 rounded-lg shadow-md my-auto">
      <div class="flex w-full h-full py-2 px-4 bg-blue-200 rounded-lg justify-between">

        
        <div class="my-auto">
          <p class="font-bold">Patient's Login</p>
        </div>
        <div class="my-auto">
        <img src={Patient} className="h-16" />                  
        </div>
      </div>
    </div>
    </a>
    
    
    <div class="pl-1 md:w-96 h-20 bg-purple-500 rounded-lg shadow-md ">
    <a href='https://doctor.docsun.health'>
      <div class="flex w-full h-full py-2 px-4  rounded-lg justify-between bg-blue-200 ">
        <div class="my-auto">
          <p class="font-bold">Doctor's Login</p>
          {/* <p class="text-lg">50%</p> */}
        </div>
        <div class="my-auto">
          <img src={Doctor} className="h-16" />
          {/* <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
</svg> */}
        </div>
      </div>
      </a>
    </div>

    </div>
   
  </div>
</body>
    
    </>  );
}
 
export default Dashboard;