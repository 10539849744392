import logo from './logo.svg';
import './App.css';
import Scan from './components/Scan';
import { Routes, Route, Link } from "react-router-dom";
import Security from './components/Security';
import Nav from './components/Nav';
import Dashboard from './components/Dashboard';
import Instructions from './components/Instructions';

function App() {
  return (
    <div>
      {/* <Scan /> */}
      {/* <Nav /> */}
      <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/instructionsooo" element={<Instructions />} />
        {/* <Route path="/security" element={<Security />} /> */}
      </Routes>
     
    </div>
  );
}

export default App;
