import React, { Component } from "react";

import SemiCircleProgressBar from "react-progressbar-semicircle";

export default class Example extends Component {
  render() {
    return <SemiCircleProgressBar percentage={33} strokeWidth={50} />;
  }
}

// const SemiCircle = () => {
//     return (  );
// }
 
// export default SemiCircle;